import { useState } from "react";
import { Box, Button, TextField, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { collection, addDoc, setDoc } from "firebase/firestore";
import { db } from "../services/firebase";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { storage } from "../services/firebase";
import SimpleBackdrop from "../components/SimpleBackdrop";
import CustomAlert from "../components/CustomAlert";
import HeaderComponent from "../components/HeaderComponent";

const FillApplication = () => {
    const [fullName, setfullName] = useState("");

    const [phoneNumber, setphoneNumber] = useState("");
    const [errorPhoneMesg, setErrorPhoneMesg] = useState("");

    const [buildingNo, setbuildingNo] = useState("");
    const [landMark, setlandMark] = useState("");
    const [city, setcity] = useState("");

    const [pinCode, setpinCode] = useState("");
    const [errorPinCode, setErrorPinCode] = useState("");

    const [state, setstate] = useState("");
    const [surveyNumber, setsurveyNumber] = useState("");

    const [file, setFile] = useState(null);
    const [errorpdf, seterrorpdf] = useState("");

    const [alertOpen, setAlertOpen] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const [screenLoading, setScreenLoading] = useState(false);
    const [alertTimestamp, setAlertTimestamp] = useState(false);

    const navigate = useNavigate();

    const showCustomAlert = (formattedDate) => {
        setAlertMessage(`Please note down your REQUEST-ID: ${formattedDate}`);
        setAlertOpen(true);
        setAlertTimestamp(formattedDate);
    };

    const handleCloseAlert = () => {
        setAlertOpen(false);
        navigate("/");
    };

    const onPhoneHandle = (e) => {
        const phone = e.target.value;
        const numericPhone = phone.replace(/\D/g, '');
        setphoneNumber(numericPhone);
        if (numericPhone.length === 10) {
            setErrorPhoneMesg("");
        } else {
            setErrorPhoneMesg("Please enter a valid 10-digit phone number.");  // Show error message
        }
    };

    const onPinCodeHandle = (e) => {
        const pin = e.target.value;
        const numericPin = pin.replace(/\D/g, '');
        setpinCode(numericPin);
        if (numericPin.length === 6) {
            setErrorPinCode("");
        } else {
            setErrorPinCode("Please enter a valid 6-digit pin code.");  // Show error message
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Remove the file validation check
        if (file && !file.name.endsWith('.pdf')) {
            seterrorpdf('Please upload a pdf file.');
            return;
        }

        setScreenLoading(true);

        try {
            let fileURL = null;

            // Only upload the file if it exists
            if (file) {
                const fileRef = ref(storage, `reports/${file.name}`);
                await uploadBytes(fileRef, file);
                fileURL = await getDownloadURL(fileRef);
            }

            const unixTimestamp = Date.now();
            const formData = {
                fullName,
                phoneNumber,
                buildingNo,
                landMark,
                city,
                pinCode,
                state,
                surveyNumber,
                surveyMapURL: "",
                invoiceURL: "",
                fileURL,  // Set fileURL to null if no file is uploaded
                isVerified: "Not Verified",
                fileName: file ? file.name : null,  // Set fileName to null if no file
                createdAt: unixTimestamp,
            };

            const docRef = await addDoc(collection(db, "survey_requests"), formData);

            const updatedFormData = {
                ...formData,
                reqId: docRef.id,
            };

            await setDoc(docRef, updatedFormData, { merge: true });

            setScreenLoading(false);
            showCustomAlert(unixTimestamp);

        } catch (error) {
            setScreenLoading(false);
            console.error("Error adding document: ", error);
            alert("There was an error submitting the form. Please try again.");
        }
    };

    return (
        <Box sx={{ maxWidth: "100%", minWidth: 300, }}>
            <HeaderComponent />

            <form onSubmit={handleSubmit} autoComplete="off" >
                <Box sx={{display:"flex", justifyContent:"center", }}>
                    <Box sx={{ width: { xs: "300px", md: "600px" } }}>
                        <TextField
                            label="Full Name"
                            name="fullName"
                            variant="outlined"
                            fullWidth
                            required
                            value={fullName}
                            onChange={(e) => setfullName(e.target.value)}
                            sx={{ marginBottom: 2, mt: 4 }}
                        />
                        <TextField
                            label="Phone Number"
                            name="phoneNumber"
                            placeholder="0000000000"
                            variant="outlined"
                            fullWidth
                            required
                            value={phoneNumber}
                            onChange={(e) => onPhoneHandle(e)}
                            inputProps={{ maxLength: 10, inputMode: 'numeric', pattern: '[0-9]*' }}
                            sx={{ marginBottom: 2 }}
                        />
                        <TextField
                            label="Survey Number"
                            name="surveyNumber"
                            variant="outlined"
                            fullWidth
                            required
                            value={surveyNumber}
                            onChange={(e) => setsurveyNumber(e.target.value)}
                            sx={{ marginBottom: 2 }}
                        />
                        <Typography sx={{ fontSize: "10px", color: "red", marginBottom: 2 }}>{errorPhoneMesg}</Typography>
                        {/* <Box> */}
                        <Typography>Address:</Typography>
                        <Box p={1} />
                        <TextField
                            label="User Address Line 1"
                            placeholder="Door.No/Falt.No/Street Name"
                            name="buildingNo"
                            variant="outlined"
                            fullWidth
                            required
                            value={buildingNo}
                            onChange={(e) => setbuildingNo(e.target.value)}
                            sx={{ marginBottom: 2 }}
                        />
                        <TextField
                            label="User Address Line 2"
                            placeholder="Landmark/Mandal"
                            name="landMark"
                            variant="outlined"
                            fullWidth
                            required
                            value={landMark}
                            onChange={(e) => setlandMark(e.target.value)}
                            sx={{ marginBottom: 2 }}
                        />
                        <TextField
                            label="City"
                            placeholder="City"
                            name="city"
                            variant="outlined"
                            fullWidth
                            required
                            value={city}
                            onChange={(e) => setcity(e.target.value)}
                            sx={{ marginBottom: 2 }}
                        />
                        <TextField
                            label="State"
                            placeholder="State"
                            name="state"
                            variant="outlined"
                            fullWidth
                            required
                            value={state}
                            onChange={(e) => setstate(e.target.value)}
                            sx={{ marginBottom: 2 }}
                        />
                        <TextField
                            label="Pin-Code"
                            placeholder="000000"
                            name="pinCode"
                            variant="outlined"
                            fullWidth
                            required
                            value={pinCode}
                            onChange={(e) => onPinCodeHandle(e)}
                            inputProps={{ maxLength: 6, inputMode: 'numeric', pattern: '[0-9]*' }}
                        />
                        <Typography sx={{ fontSize: "10px", color: "red", marginBottom: 2 }}>{errorPinCode}</Typography>


                        {/* </Box> */}

                        <Typography>You can upload any relavent document (optional)</Typography>
                        <TextField
                            name='file'
                            variant="outlined"
                            fullWidth
                            // onChange={(event) => setFile(event.target.files[0])}
                            onChange={(event) => {
                                const selectedFile = event.target.files[0];
                                if (selectedFile && selectedFile.type === 'application/pdf') {
                                    setFile(selectedFile);
                                } else {
                                    setFile(null);
                                    seterrorpdf('Please upload a PDF file.');
                                }
                            }}
                            sx={{ marginBottom: 2 }}
                            type='file'
                            id="outlined-basic"
                            InputLabelProps={{ shrink: true }}
                        />
                        <Typography sx={{ fontSize: "10px", color: "red", marginBottom: 2 }}>{errorpdf}</Typography>
                        <Button variant="contained" color="primary" type="submit" fullWidth>
                            Submit
                        </Button>
                    </Box>
                </Box>
            </form>
            <Box p={2} />
            <SimpleBackdrop open={screenLoading} />
            <CustomAlert open={alertOpen} message={alertMessage} onClose={handleCloseAlert} alertTimestamp={alertTimestamp} />
        </Box>
    );
};

export default FillApplication;