import { Box, Button } from "@mui/material";
import HeaderComponent from "../components/HeaderComponent";
import geo1 from "../Images/geo1.jpg";
import geo2 from "../Images/geo2.jpg";
import geo3 from "../Images/geo3.jpg";
const HomeLayout = () => {

    return (
        <Box sx={{
            height: "100vh",
            width: "100vw",
        }}>
            <HeaderComponent />
            <Box sx={{
                height: "calc(100vh - 5rem)",
                width: "100vw",
                display:"flex",
                flexDirection:{xs:"column", md:"row"},
                alignItems:"center",
                justifyContent:"center"
            }}>

                <Box component="img"
                    src={geo1}
                    alt="Logo"
                    sx={{
                        width: {xs:"250px", md:"450px"},
                        height:{xs:"250px", md:"450px"},
                        m: 2
                    }}
                />

                <Box component="img"
                    src={geo2}
                    alt="Logo"
                    sx={{
                        width: {xs:"250px", md:"450px"},
                        height:{xs:"250px", md:"450px"},
                        m: 2
                    }}
                />
                <Box component="img"
                    src={geo3}
                    alt="Logo"
                    sx={{
                        width: {xs:"250px", md:"450px"},
                        height:{xs:"250px", md:"450px"},
                        m: 2
                    }}
                />
            </Box>

        </Box>
    )
}
export default HomeLayout;