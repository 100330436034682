import { Box, Button } from "@mui/material";
import logo from "../Images/geoconLogo.png";
import { useNavigate } from "react-router-dom";

const HeaderComponent = () => {
    const navigate = useNavigate();
    return (
        <Box sx={{
            maxWidth: "100%",
            minWidth: "300px",
            display: "flex",
            justifyContent: { xs: "center", md: "space-between" },
            flexDirection: { xs: "column", md: "row" },
            alignItems: "center",
            height: { xs: "auto", md: "5rem" },
            boxShadow: "0px 8px 8px -4px rgba(0, 0, 0, 0.07)",
            flexWrap: "wrap"
        }}>
            <Box sx={{
                height: "100%",
                display: "flex",
                justifyContent: { xs: "center", md: "start" },
                alignItems: "center"
            }}>
                <Box component="img"
                    src={logo}
                    alt="Logo"
                    onClick={() => navigate("/")}
                    sx={{
                        width: { xs: "300px", md: "350px" },
                        marginLeft: { xs: 0, md: 2 }, cursor: "pointer",
                    }}
                />
            </Box>

            <Box sx={{
                display: "flex", alignItems: "center", gap: "1rem", justifyContent: { md: "end", xs: "center" }, pr: 2, pl: 2,
                flexWrap: "wrap", backgroundColor: "#435595", height: "100%"
            }}>
                <Button
                    sx={{
                        width: "250px",
                        fontWeight: "bold",
                        color: "white",
                        "&:hover": {
                            backgroundColor: "green",
                            color: "white",
                            borderColor: "green",
                        },
                    }}
                    onClick={() => navigate("/my_status")} >My Request ' s Status</Button>
                <Button sx={{
                    width: "250px",
                    fontWeight: "bold",
                    color: "white",
                    "&:hover": {
                        backgroundColor: "green",
                        color: "white",
                        borderColor: "green",
                    },
                }} onClick={() => navigate("/request_survey")} >request survey</Button>
                <Button
                    sx={{
                        width: "250px",
                        fontWeight: "bold",
                        color: "white",
                        "&:hover": {
                            backgroundColor: "green",
                            color: "white",
                            borderColor: "green",
                        },
                    }}
                    onClick={() => navigate("/geocon_admin")} >Admin Login</Button>
            </Box>
        </Box>
    )
}
export default HeaderComponent;