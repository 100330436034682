import { Box, Button, Modal, Typography } from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

const CustomAlert = ({ open, message, onClose, alertTimestamp }) => {
    const navigate = useNavigate();
    const [btntxt, setBtntxt] = useState("copy");
    const [isDisabled, setIsDisabled] = useState(false);

    const handleCopy = () => {
        navigator.clipboard.writeText(alertTimestamp.toString())
            .then(() => {
                // console.log('Timestamp copied to clipboard!');
                setBtntxt("Copied");
                setIsDisabled(true);
                setTimeout(() => {
                    setBtntxt("copy");
                    setIsDisabled(false);
                }, 5000);
            })
            .catch(err => {
                setBtntxt("error");
                console.error('Failed to copy:', err);
                setIsDisabled(true);
                setTimeout(() => {
                    setBtntxt("copy");
                    setIsDisabled(false);
                }, 5000);
            });
    };

    const handleClose = () => {
        onClose();
        navigate("/");
    };

    return (
        <Modal open={open} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Box sx={{
                backgroundColor: 'white', padding: 2, borderRadius: 1, boxShadow: 24,
                textAlign: 'center', width: 300
            }}>
                <Typography variant="h6">{message}</Typography>
                <Box sx={{ display:"flex", justifyContent:"space-around", alignItems:"center", mt:3 }}>
                    <Button variant="outlined" onClick={handleCopy} 
                        disabled={isDisabled}
                        sx={{
                        "&:hover": {
                            backgroundColor: "#1976d2",
                            color: "white",
                        }
                    }}>
                        {btntxt}
                    </Button>
                    <Button variant="contained" color="primary" onClick={handleClose} sx={{
                        "&:hover": {
                            backgroundColor: "white",
                            fontWeight:"bold",
                            color: "#1976d2",
                        }
                    }}>
                        OK
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
};
export default CustomAlert;