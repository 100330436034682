import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { Box, Button, TextField, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import logo from "../Images/geoconLogo.png";

const schema = z.object({
    email: z.string().email({ message: "Invalid email address" }),
    password: z
        .string()
        .min(8, { message: "Password must be at least 8 characters" }),
});

const AdminLoginPage = () => {
    const geouser = localStorage.getItem("geouser");
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: zodResolver(schema),
    });

    const geouserId = "geocon@admin.com";
    const pwd = "admin@123";

    const navigate = useNavigate();
    const [loginError, setLoginError] = useState("");

    useEffect(() => {
        if (geouser) {
            navigate("/dashboard");
        }
    }, [geouser, navigate]);

    const onSubmit = async (data) => {
        setLoginError("");
        try {
            const { email, password } = data;
            if (email === geouserId && password === pwd) {
                localStorage.setItem("geouser", "geocon");
                navigate("/dashboard");
            } else {
                setLoginError("Invalid email or password");
            }
        } catch (error) {
            setLoginError("An error occurred. Please try again.");
        }
    };


    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: { xs: "column", md: "row" },
                height: "100vh",
            }}
        >
            <Box
                sx={{
                    width: { xs: "100%", md: "70%" },
                    backgroundColor: "white",
                    display: { xs: "none", md: "block" },
                    borderRight: "3px solid lightgray"
                }}
            >
                <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
                    <Box component="img"
                        src={logo}
                        alt="Logo"
                        onClick={()=>navigate("/")}
                        sx={{
                            width: { xs: "300px", md: "500px" }, cursor:"pointer",
                            boxShadow: "rgba(3, 102, 214, 0.3) 0px 0px 0px 3px"
                        }}
                    />
                </Box>
            </Box>

            <Box
                sx={{
                    width: { md: "30%" },
                    p: { xs: 2, md: 4 },
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                }}
            >
                <Box sx={{ display: {xs:"flex", md:"none"}, justifyContent: "center", alignItems: "center", height: "20%" }}>
                    <Box component="img"
                        src={logo}
                        alt="Logo"
                        onClick={()=>navigate("/")}
                        sx={{
                            width: { xs: "300px", md: "500px" }, cursor:"pointer",
                            boxShadow: "rgba(3, 102, 214, 0.3) 0px 0px 0px 3px"
                        }}
                    />
                </Box>
                <Typography
                    variant="h4"
                    gutterBottom
                    sx={{ fontSize: { xs: "1.5rem", md: "2.125rem" } }}
                >
                    Welcome back!
                </Typography>
                <Typography
                    variant="body1"
                    gutterBottom
                    sx={{ fontSize: { xs: "0.875rem", md: "1rem" } }}
                >
                    Enter to get unlimited access to data & information.
                </Typography>

                <form onSubmit={handleSubmit(onSubmit)}>
                    <TextField
                        label="Email"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        {...register("email")}
                        error={!!errors.email}
                        helperText={errors.email ? errors.email.message : ""}
                        sx={{ fontSize: { xs: "0.875rem", md: "1rem" } }}
                    />
                    <TextField
                        label="Password"
                        type="password"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        {...register("password")}
                        error={!!errors.password}
                        helperText={errors.password ? errors.password.message : ""}
                        sx={{ fontSize: { xs: "0.875rem", md: "1rem" } }}
                    />
                    {loginError && (
                        <Typography color="error" sx={{ fontSize: "0.875rem", mt: 1 }}>
                            {loginError}
                        </Typography>
                    )}
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        fullWidth
                        sx={{ mt: 2, fontSize: { xs: "0.875rem", md: "1rem" } }}
                    >
                        Log In
                    </Button>
                </form>
            </Box>
        </Box>
    );
};
export default AdminLoginPage;