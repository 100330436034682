import { useState } from "react";
import { Box, Button, TextField, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { collection, query, where, getDocs } from "firebase/firestore";
import { db } from "../services/firebase";
import SimpleBackdrop from "../components/SimpleBackdrop";
import HeaderComponent from "../components/HeaderComponent";

const StatusRequest = () => {
    const navigate = useNavigate();
    const [statusAvailable, setStatusAvailable] = useState("");
    const [dataAvailable, setdataAvailable] = useState({});
    const [rCodeNumber, setRCodeNumber] = useState("");
    const [errorRCodeMesg, setErrorRCodeMesg] = useState("");
    const [screenLoading, setScreenLoading] = useState(false);

    const onRCodeHandle = (e) => {
        const Code = e.target.value;
        const numericRCode = Code.replace(/\D/g, '');
        setRCodeNumber(numericRCode);
        if (numericRCode.length === 13) {
            setErrorRCodeMesg("");
        } else {
            setErrorRCodeMesg("Please enter a valid 13-digit Request-ID number.");  // Show error message
        }
    };

    const onCheckClick = async () => {
        setScreenLoading(true);
        try {
            const q = query(
                collection(db, 'survey_requests'),
                where("createdAt", "==", parseInt(rCodeNumber))
            );
            // console.log(rCodeNumber)
            // const q = db.collection('survey_requests').where("createdAt", "==", rCodeNumber).get()
            // console.log(q)
            const querySnapshot = await getDocs(q);
            if (!querySnapshot.empty) {
                querySnapshot.forEach((doc) => {
                    const data = doc.data();
                    setStatusAvailable(`${data.isVerified}`);
                    setdataAvailable(data);
                });
            } else {
                setStatusAvailable("No document found with this Request-ID.");
            }
            setScreenLoading(false);

        } catch (error) {
            setScreenLoading(false);
            console.error("Error fetching document: ", error);
            // alert("There was an error occured. Please try again.");
        }
    }

    return (
        <Box sx={{ maxWidth: "100%", minWidth: 300, }}>
            <HeaderComponent />
            <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
                <Box sx={{ width: "300px", }}>
                    <TextField
                        label="Request ID"
                        name="rCodeNumber"
                        placeholder="0000000000000"
                        variant="outlined"
                        fullWidth
                        required
                        value={rCodeNumber}
                        onChange={(e) => onRCodeHandle(e)}
                        inputProps={{ maxLength: 13, inputMode: 'numeric', pattern: '[0-9]*' }}
                    />
                    <Typography sx={{ fontSize: "10px", color: "red", marginBottom: 2 }}>{errorRCodeMesg}</Typography>
                    <Button variant="contained" color="primary" fullWidth onClick={() => onCheckClick()}>
                        Check Status
                    </Button>
                    <SimpleBackdrop open={screenLoading} />
                </Box>
            </Box>
            <Box sx={{ display: "flex", justifyContent: "center",}}>
            {
                statusAvailable === "Verified" &&
                <Box sx={{
                    boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                    p: 2, mt: 3,
                }}>
                    <Typography>Application Status <span style={{ color: "green", fontWeight: "bold" }}>{statusAvailable}</span></Typography>
                    <Box p={1} />
                    <Box sx={{ display: "flex", alignItems: "center", gap: 2, p: 2, boxShadow: "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px", }}>
                        <Typography> Survey Map Attachment:</Typography>
                        {dataAvailable.surveyMapURL ? (
                            <Button
                                variant="outlined"
                                onClick={() => window.open(dataAvailable.surveyMapURL, '_blank')}
                            >
                                Download PDF
                            </Button>
                        ) : (
                            <Typography sx={{ color: 'gray' }}>
                                No attachment available
                            </Typography>
                        )}
                    </Box>
                    <Box p={1} />
                    <Box sx={{ display: "flex", alignItems: "center", gap: 2, p: 2, boxShadow: "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px", }}>
                        <Typography> Invoice Attachment:</Typography>
                        {dataAvailable.invoiceURL ? (
                            <Button
                                variant="outlined"
                                onClick={() => window.open(dataAvailable.invoiceURL, '_blank')}
                            >
                                Download PDF
                            </Button>
                        ) : (
                            <Typography sx={{ color: 'gray' }}>
                                No attachment available
                            </Typography>
                        )}
                    </Box>
                </Box>
            }
            {
                ((statusAvailable === "Not Verified") || (statusAvailable === "Rejected")) &&
                <Box sx={{
                    boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                    p: 2, mt: 3,
                }}>
                    <Typography >Application Status <span style={{ color: "red" }}>{statusAvailable}</span></Typography>
                </Box>
            }
            {
                (statusAvailable === "No document found with this Request-ID.") &&
                <Box sx={{
                    boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                    p: 2, mt: 3,
                }}>
                    <Typography sx={{ color: "red" }}>{statusAvailable}</Typography>
                </Box>
            }
        </Box>
        </Box>
    );
};

export default StatusRequest;