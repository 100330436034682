import React, { useState, useEffect, useCallback } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Card from "react-bootstrap/Card";
import InvoiceItem from "./InvoiceItem";
import InvoiceModal from "./InvoiceModal";
import { Box, TextField, Typography } from "@mui/material";

const InvoiceForm = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [currency, setCurrency] = useState("$");
  const [currentDate, setCurrentDate] = useState(
    new Date().toLocaleDateString()
  );
  const [invoiceNumber, setInvoiceNumber] = useState(1);
  const [serviceType, setServiceType] = useState("");
  const [dateOfIssue, setDateOfIssue] = useState("");
  const [billTo, setBillTo] = useState("");
  const [concernPerson, setConcernPerson] = useState("");
  const [billToEmail, setBillToEmail] = useState("");
  const [billToAddress, setBillToAddress] = useState("");
  const [billToPhone, setBillToPhone] = useState("");
  const [billToState, setBillToState] = useState("");
  const [gstNo, setGstNo] = useState("");
  const [billToMrOrMiss, setBillToMrOrMiss] = useState("");
  const [billToGstNo, setBillToGstNo] = useState("");
  const [billFromState, setBillFromState] = useState("");
  const [billFrom, setBillFrom] = useState("");
  const [billFromEmail, setBillFromEmail] = useState("");
  const [billFromAddress, setBillFromAddress] = useState("");
  const [notes, setNotes] = useState(
    "Thank you for doing business with us. Have a great day!"
  );
  const [total, setTotal] = useState("0.00");
  const [subTotal, setSubTotal] = useState("0.00");
  const [taxRate, setTaxRate] = useState("");
  const [taxAmount, setTaxAmount] = useState("0.00");
  const [discountRate, setDiscountRate] = useState("");
  const [discountAmount, setDiscountAmount] = useState("0.00");

  const [gstVal, setgstVal] = useState(0);
  const [compName, setCompName] = useState("");
  const [compPan, setCompPan] = useState("");
  const [bankName, setbankName] = useState("");
  const [accNum, setaccNum] = useState("");
  const [phNum, setphNum] = useState("");
  const [ifscCode, setifscCode] = useState("");
  const [emailID, setemailID] = useState("");

  const [items, setItems] = useState([
    {
      id: (+new Date() + Math.floor(Math.random() * 999999)).toString(36),
      name: "",
      description: "",
      price: "1.00",
      quantity: 1,
    },
  ]);

  const handleCalculateTotal = useCallback(() => {
    let newSubTotal = items
      .reduce((acc, item) => {
        return acc + parseFloat(item.price) * parseInt(item.quantity);
      }, 0)
      .toFixed(2);

    let newtaxAmount = (newSubTotal * (taxRate / 100)).toFixed(2);
    let newdiscountAmount = (newSubTotal * (discountRate / 100)).toFixed(2);
    let newTotal = (
      newSubTotal -
      newdiscountAmount +
      parseFloat(newtaxAmount)
    ).toFixed(2);

    setSubTotal(newSubTotal);
    setTaxAmount(newtaxAmount);
    setDiscountAmount(newdiscountAmount);
    setTotal(newTotal);
  }, [items, taxRate, discountRate]);

  useEffect(() => {
    handleCalculateTotal();
  }, [handleCalculateTotal]);

  const handleRowDel = (item) => {
    const updatedItems = items.filter((i) => i.id !== item.id);
    setItems(updatedItems);
  };

  const handleAddEvent = () => {
    const id = (+new Date() + Math.floor(Math.random() * 999999)).toString(36);
    const newItem = {
      id,
      name: "",
      price: "1.00",
      description: "",
      quantity: 1,
    };
    setItems([...items, newItem]);
  };

  const onItemizedItemEdit = (evt) => {
    const { id, name, value } = evt.target;

    console.log(id, name, value);

    const updatedItems = items.map((item) =>
      item.id === id ? { ...item, [name]: value } : item
    );
    setItems(updatedItems);
  };

  const handleChange = (setter) => (event) => {
    setter(event.target.value);
    handleCalculateTotal();
  };

  const openModal = (event) => {
    event.preventDefault();
    handleCalculateTotal();
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };
  const companyLogo = "https://firebasestorage.googleapis.com/v0/b/geocon-877d6.appspot.com/o/webdata%2Flogo.png?alt=media&token=1cadfea1-44a0-4267-9e30-e4a2de419cd3";

  const totalBillAmount = items.reduce((acc, item) => {
    return (
      acc +
      item.perUnitPrice * item.quantity +
      (item.perUnitPrice * gstVal) / 100 +
      (item.perUnitPrice * gstVal) / 100
    );
  }, 0);

  return (
    <Form onSubmit={openModal}>
      <Row>
        <Col md={8} lg={9}>
          <Card className="p-4 p-xl-5 my-3 my-xl-4">
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                src={companyLogo}
                style={{
                  width: "400px",
                  textAlign: "center",
                }}
              />
            </div>

            <div className="d-flex flex-row align-items-start justify-content-between mb-3">
              <div className="d-flex flex-column">
                <div className="d-flex flex-column">
                  <div className="mb-2">
                    <div className="d-flex flex-row align-items-center">
                      <span className="fw-bold me-2">
                        Invoice&nbsp;No:&nbsp;
                      </span>
                      <Form.Control
                        type="text"
                        value={invoiceNumber}
                        name="invoiceNumber"
                        onChange={handleChange(setInvoiceNumber)}
                        min="1"
                        style={{ maxWidth: "70px" }}
                        required
                      />
                    </div>
                  </div>
                </div>
                <div className="d-flex flex-row align-items-center">
                  <span className="fw-bold d-block me-2">Date:</span>
                  <Form.Control
                    type="date"
                    value={dateOfIssue}
                    name="dateOfIssue"
                    onChange={handleChange(setDateOfIssue)}
                    style={{ maxWidth: "150px" }}
                    required
                  />
                </div>
                <div className="d-flex flex-row align-items-center mt-2">
                  <span className="fw-bold d-block me-2 w-50">
                    Service Type:
                  </span>
                  <Form.Control
                    type="text"
                    value={serviceType}
                    name="serviceType"
                    onChange={handleChange(setServiceType)}
                    required
                  />
                </div>
              </div>
            </div>
            <hr className="my-4" />
            <Row className="mb-5">
              <Col>
                <Form.Label className="fw-bold">Bill from:</Form.Label>
                <Form.Control
                  placeholder="Who is this invoice from?"
                  rows={3}
                  value={billFrom}
                  type="text"
                  name="billFrom"
                  className="my-2"
                  onChange={handleChange(setBillFrom)}
                  autoComplete="name"
                  required
                />
                {/* <Form.Control
                  placeholder="Email address"
                  value={billFromEmail}
                  type="email"
                  name="billFromEmail"
                  className="my-2"
                  onChange={handleChange(setBillFromEmail)}
                  autoComplete="email"
                  required
                /> */}
                <Form.Control
                  placeholder="Billing address"
                  value={billFromAddress}
                  type="text"
                  name="billFromAddress"
                  className="my-2"
                  autoComplete="address"
                  onChange={handleChange(setBillFromAddress)}
                  required
                />

                <Form.Control
                  placeholder="GST No"
                  value={gstNo}
                  type="text"
                  name="gstNo"
                  className="my-2"
                  onChange={handleChange(setGstNo)}
                  required
                />
                <Form.Control
                  placeholder="State"
                  value={billFromState}
                  type="text"
                  name="billFromState"
                  className="my-2"
                  onChange={handleChange(setBillFromState)}
                  required
                />
              </Col>
              <Col>
                <Form.Label className="fw-bold">Bill to:</Form.Label>
                <Form.Control
                  placeholder="Who is this invoice to?"
                  rows={3}
                  value={billTo}
                  type="text"
                  name="billTo"
                  className="my-2"
                  onChange={handleChange(setBillTo)}
                  autoComplete="name"
                  required
                />
                {/* <Form.Control
                  placeholder="Email address"
                  value={billToEmail}
                  type="email"
                  name="billToEmail"
                  className="my-2"
                  onChange={handleChange(setBillToEmail)}
                  autoComplete="email"
                  required
                /> */}
                {/* Mr & Miss */}
                <Form.Select value={billToMrOrMiss} onChange={handleChange(setBillToMrOrMiss)}>
                  <option value="Mr.">Mr.</option>
                  <option value="Miss.">Miss.</option>
                </Form.Select>
                <Form.Control
                  placeholder="Concern Person"
                  value={concernPerson}
                  type="text"
                  name="concernPerson"
                  className="my-2"
                  onChange={handleChange(setConcernPerson)}
                  required
                />
                <Form.Control
                  placeholder="Billing address"
                  value={billToAddress}
                  type="text"
                  name="billToAddress"
                  className="my-2"
                  autoComplete="address"
                  onChange={handleChange(setBillToAddress)}
                  required
                />
                <Form.Control
                  placeholder="Phone"
                  value={billToPhone}
                  type="text"
                  name="billToPhone"
                  className="my-2"
                  onChange={handleChange(setBillToPhone)}
                  required
                />
                <Form.Control
                  placeholder="GST No"
                  value={billToGstNo}
                  type="text"
                  name="billToGstNo"
                  className="my-2"
                  onChange={handleChange(setBillToGstNo)}
                  required
                />
                <Form.Control
                  placeholder="State"
                  value={billToState}
                  type="text"
                  name="billToState"
                  className="my-2"
                  onChange={handleChange(setBillToState)}
                  required
                />
              </Col>
            </Row>
            <TextField
              label="GST % "
              placeholder="9%"
              name="gstVal"
              variant="outlined"
              fullWidth
              type="number"
              value={gstVal}
              onChange={(e) => setgstVal(e.target.value)}
              sx={{ width: "6rem" }}
            />
            <Box p={0.2} />
            <Typography>Note: If Added 9% it takes 18% as cgst and sgst equal values</Typography>
            <Box p={0.5} />
            <InvoiceItem
              onItemizedItemEdit={onItemizedItemEdit}
              onRowAdd={handleAddEvent}
              onRowDel={handleRowDel}
              currency={currency}
              items={items}
            />
            <Row className="mt-4 justify-content-end">
              <Col lg={6}>
                {/* <div className="d-flex flex-row align-items-start justify-content-between">
                  <span className="fw-bold">Subtotal:</span>
                  <span>
                    {currency}
                    {subTotal}
                  </span>
                </div>
                <div className="d-flex flex-row align-items-start justify-content-between mt-2">
                  <span className="fw-bold">Discount:</span>
                  <span>
                    <span className="small ">({discountRate || 0}%)</span>
                    {currency}
                    {discountAmount || 0}
                  </span>
                </div>
                <div className="d-flex flex-row align-items-start justify-content-between mt-2">
                  <span className="fw-bold">Tax:</span>
                  <span>
                    <span className="small ">({taxRate || 0}%)</span>
                    {currency}
                    {taxAmount || 0}
                  </span>
                </div> */}
                <hr />
                <div
                  className="d-flex flex-row align-items-start justify-content-between"
                  style={{ fontSize: "1.125rem" }}
                >
                  <span className="fw-bold">Total:</span>
                  <span className="fw-bold">
                    {currency}
                    {totalBillAmount || 0}
                  </span>
                </div>
              </Col>
            </Row>
            <hr className="my-4" />

            <Box p={1} />
            <Box>
              <Typography sx={{ fontWeight: "bold" }}>Fill Bank Details:</Typography>
              <Box p={1} />
              <Box sx={{ display: "flex", justifyContent: "space-between", gap: "1rem" }}>
                <TextField
                  label="Comapny Name"
                  placeholder="Company Name"
                  name="compName"
                  variant="outlined"
                  fullWidth
                  required
                  value={compName}
                  onChange={(e) => setCompName(e.target.value)}
                  sx={{ marginBottom: 2 }}
                />
                <TextField
                  label="Bank Name"
                  placeholder="Bank Name"
                  name="bankName"
                  variant="outlined"
                  fullWidth
                  required
                  value={bankName}
                  onChange={(e) => setbankName(e.target.value)}
                  sx={{ marginBottom: 2 }}
                />
              </Box>
              <Box p={.5} />
              <Box sx={{ display: "flex", justifyContent: "space-between", gap: "1rem" }}>
                <TextField
                  label="Account Number"
                  placeholder="Account Number"
                  name="accNum"
                  variant="outlined"
                  fullWidth
                  required
                  value={accNum}
                  onChange={(e) => setaccNum(e.target.value)}
                  sx={{ marginBottom: 2 }}
                />
                <TextField
                  label="IFSC Code"
                  placeholder="IFSC Code"
                  name="ifscCode"
                  variant="outlined"
                  fullWidth
                  required
                  value={ifscCode}
                  onChange={(e) => setifscCode(e.target.value)}
                  sx={{ marginBottom: 2 }}
                />
              </Box>
              <Box p={.5} />
              <Box sx={{ display: "flex", justifyContent: "space-between", gap: "1rem" }}>
                <TextField
                  label="Phone Number"
                  placeholder="Phone Number"
                  name="phNum"
                  variant="outlined"
                  fullWidth
                  required
                  value={phNum}
                  onChange={(e) => setphNum(e.target.value)}
                  sx={{ marginBottom: 2 }}
                />
                <TextField
                  label="Comapny Pan"
                  placeholder="Company Pan"
                  name="compPan"
                  variant="outlined"
                  fullWidth
                  required
                  value={compPan}
                  onChange={(e) => setCompPan(e.target.value)}
                  sx={{ marginBottom: 2 }}
                />
              </Box>
              <Box p={.5} />
              <TextField
                label="Email ID"
                placeholder="Email ID"
                name="emailID"
                variant="outlined"
                fullWidth
                required
                value={emailID}
                onChange={(e) => setemailID(e.target.value)}
                sx={{ marginBottom: 2 }}
              />
            </Box>
            <Form.Label className="fw-bold">Notes:</Form.Label>
            <Form.Control
              placeholder="Thank you for doing business with us. Have a great day!"
              name="notes"
              value={notes}
              onChange={handleChange(setNotes)}
              as="textarea"
              className="my-2"
              rows={1}
            />
          </Card>
        </Col>
        <Col md={4} lg={3}>
          <div className="sticky-top pt-md-3 pt-xl-4">
            <InvoiceModal
              showModal={isOpen}
              closeModal={closeModal}
              info={{
                dateOfIssue,
                invoiceNumber,
                serviceType,
                billTo,
                billToEmail,
                billToAddress,
                billFrom,
                billFromEmail,
                billFromAddress,
                notes,
                gstNo,
                billToPhone,
                billToState,
                billFromState,
                companyLogo,
                concernPerson,
                billToGstNo,
                billToMrOrMiss,
              }}
              items={items}
              currency={currency}
              subTotal={subTotal}
              taxAmount={taxAmount}
              discountAmount={discountAmount}
              total={total}
              compName={compName}
              compPan={compPan}
              accNum={accNum}
              phNum={phNum}
              ifscCode={ifscCode}
              bankName={bankName}
              emailID={emailID}
            />

            <Form.Group className="mb-3">
              <Form.Label className="fw-bold">Currency:</Form.Label>
              <Form.Select
                onChange={(e) => {
                  setCurrency(e.target.value);
                }}
                className="btn btn-light my-1"
                aria-label="Change Currency"
              >
                <option value="$">USD (United States Dollar)</option>
                <option value="£">GBP (British Pound Sterling)</option>
                <option value="₹">INR (Indian Rupee)</option>
                <option value="¥">JPY (Japanese Yen)</option>
                <option value="$">CAD (Canadian Dollar)</option>
                <option value="$">AUD (Australian Dollar)</option>
                <option value="$">SGD (Singapore Dollar)</option>
                <option value="¥">CNY (Chinese Renminbi)</option>
                <option value="₿">BTC (Bitcoin)</option>
              </Form.Select>
            </Form.Group>
            {/* <Form.Group className="my-3">
              <Form.Label className="fw-bold">Tax rate:</Form.Label>
              <InputGroup className="my-1 flex-nowrap">
                <Form.Control
                  name="taxRate"
                  type="number"
                  value={taxRate}
                  onChange={handleChange(setTaxRate)}
                  className="bg-white border"
                  placeholder="0.0"
                  min="0.00"
                  step="0.01"
                  max="100.00"
                />
                <InputGroup.Text className="bg-light fw-bold text-secondary small">
                  %
                </InputGroup.Text>
              </InputGroup>
            </Form.Group> */}
            {/* <Form.Group className="my-3">
              <Form.Label className="fw-bold">Discount rate:</Form.Label>
              <InputGroup className="my-1 flex-nowrap">
                <Form.Control
                  name="discountRate"
                  type="number"
                  value={discountRate}
                  onChange={handleChange(setDiscountRate)}
                  className="bg-white border"
                  placeholder="0.0"
                  min="0.00"
                  step="0.01"
                  max="100.00"
                />
                <InputGroup.Text className="bg-light fw-bold text-secondary small">
                  %
                </InputGroup.Text>
              </InputGroup>
            </Form.Group> */}
            <hr className="mt-4 mb-3" />
            <Button
              variant="primary"
              type="submit"
              className="d-block w-100 btn-secondary"
            >
              Review Invoice
            </Button>
          </div>
        </Col>
      </Row>
    </Form>
  );
};

export default InvoiceForm;
