import { Box, Button } from "@mui/material";
import gicon from "../Images/geoconLogo.png";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import FindStatus from "../Pages/FindStatus";
import CreateInvoice from "../Pages/CreateInvoice";
import InvoiceForm from "../Pages/invoice/InvoiceForm";

const AdminDashboard = () => {
    const navigate = useNavigate();
    const [isInvoice, setIsInvoice] = useState(false);
    const onLogoutClick = () => {
        localStorage.removeItem("geouser");
        navigate("/geocon_admin")
    }
    return (
        <Box>
            <Box sx={{
                display: "flex", justifyContent: { xs: "center", md: "space-between" }, alignItems: "center", maxWidth: "100%",
                minWidth: "300px", flexDirection: { xs: "column", md: "row" },
                height: { xs: "auto", md: "5rem" },
                boxShadow: "0px 8px 8px -4px rgba(0, 0, 0, 0.07)",
                flexWrap: "wrap"
            }} >
                <Box sx={{
                    display: "flex", justifyContent: { xs: "center", md: "start" }, alignItems: "center",
                    height: "100%",
                }}>
                    <Box component="img"
                        src={gicon}
                        alt="Logo"
                        sx={{
                            width: { xs: "300px", md: "350px" },
                            cursor: "pointer", marginLeft: { xs: 0, md: 2 },
                        }}
                        onClick={() => navigate("/")}
                    />
                </Box>
                <Box sx={{
                    display: "flex", alignItems: "center", gap: "1rem", justifyContent: { md: "end", xs: "center" }, p: 2, 
                    flexWrap: "wrap", backgroundColor: "#435595", height: "100%"
                }}>
                    <Button variant="contained"
                        color={isInvoice ? "primary" : "success"}
                        onClick={() => setIsInvoice(false)} >Check Request</Button>
                    <Button variant="contained"
                        color={isInvoice ? "success" : "primary"}
                        onClick={() => setIsInvoice(true)} >Create Invoice</Button>
                    <Button sx={{
                    width: "180px",
                    fontWeight: "bold",
                        color: "white",
                        "&:hover": {
                            backgroundColor: "green",
                            color: "white",
                            borderColor: "green",
                        },
                    }} onClick={() => onLogoutClick()}>Logout</Button>
                </Box>
            </Box>
            {
                isInvoice ? <InvoiceForm /> : <FindStatus />
            }

        </Box>
    )
}
export default AdminDashboard;