import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

export default function SimpleBackdrop({ open }) {
  return (
    <Backdrop
      sx={(theme) => ({ color: '#00000082', zIndex: theme.zIndex.drawer + 1, backgroundColor:"#0000001f" })}
      open={open}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  );
}