import { useState } from "react";
import { Box, Button, TextField, Typography, Select, MenuItem } from "@mui/material";
import { collection, query, where, getDocs, doc, updateDoc } from "firebase/firestore";
import { db, storage } from "../services/firebase";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import SimpleBackdrop from "../components/SimpleBackdrop";
import gioIcon from "../Images/gioIcon.jpeg";

const FindStatus = () => {
    const [statusAvailable, setStatusAvailable] = useState(false);
    const [dataAvailable, setDataAvailable] = useState({});
    const [rCodeNumber, setRCodeNumber] = useState("");
    const [errorRCodeMesg, setErrorRCodeMesg] = useState("");
    const [screenLoading, setScreenLoading] = useState(false);
    const [isVerified, setIsVerified] = useState("Not Verified");
    const [surveyMap, setSurveyMap] = useState(null);
    const [invoice, setInvoice] = useState(null);
    const [formattedDate, setFormattedDate] = useState("");

    const onRCodeHandle = (e) => {
        const Code = e.target.value;
        const numericRCode = Code.replace(/\D/g, '');
        setRCodeNumber(numericRCode);
        if (numericRCode.length === 13) {
            setErrorRCodeMesg("");
        } else {
            setErrorRCodeMesg("Please enter a valid 13-digit Request-ID number.");
        }
    };

    const onCheckClick = async () => {
        setScreenLoading(true);
        setStatusAvailable(false);
        try {
            const q = query(
                collection(db, 'survey_requests'),
                where("createdAt", "==", parseInt(rCodeNumber))
            );
            const querySnapshot = await getDocs(q);
            if (!querySnapshot.empty) {
                querySnapshot.forEach((doc) => {
                    const data = doc.data();
                    // console.log(data);
                    setDataAvailable(data);
                    setIsVerified(data.isVerified || "Not Verified");
                    const dateObj = new Date(data.createdAt);
                    const formatted = `${dateObj.getFullYear()}/${String(dateObj.getMonth() + 1).padStart(2, '0')}/${String(dateObj.getDate()).padStart(2, '0')}`;
                    setFormattedDate(formatted);
                });
            } else {
                console.log("error");
                setStatusAvailable(true);
            }
            setScreenLoading(false);
        } catch (error) {
            setStatusAvailable(false);
            setScreenLoading(false);
            console.error("Error fetching document: ", error);
            setErrorRCodeMesg("Error fetching document, try again later.")
        }
    }

    const handleFileChange = (e, setFile) => {
        const file = e.target.files[0];
        if (file && file.type === "application/pdf") {
            setFile(file);
        } else {
            alert("Only PDF files are allowed.");
        }
    };

    const onSubmit = async () => {
        setScreenLoading(true);
        const documentRef = doc(db, "survey_requests", dataAvailable.reqId);
        const uploadFile = async (file, fileName) => {
            const fileRef = ref(storage, `reports/${fileName}`);
            await uploadBytes(fileRef, file);
            return await getDownloadURL(fileRef);
        };
        try {
            let surveyMapURL = dataAvailable.surveyMapURL || "";
            let invoiceURL = dataAvailable.invoiceURL || "";
            if (surveyMap) surveyMapURL = await uploadFile(surveyMap, `surveyMap_${rCodeNumber}.pdf`);
            if (invoice) invoiceURL = await uploadFile(invoice, `invoice_${rCodeNumber}.pdf`);
            await updateDoc(documentRef, {
                isVerified,
                surveyMapURL,
                invoiceURL
            });
            alert("Document updated successfully.");
            window.location.reload();
        } catch (error) {
            console.error("Error updating document: ", error);
            setErrorRCodeMesg("Error updating document, try again later.");
        }
        setScreenLoading(false);
    };

    return (
        <Box sx={{ maxWidth: "100vw", minWidth: 300, display: "flex", flexDirection: "column", alignItems: "center", gap: "1.5rem" }}>
            <Box sx={{ width: "300px" }}>
                <Box p={1} />
                <TextField
                    label="Request ID"
                    name="rCodeNumber"
                    placeholder="0000000000000"
                    variant="outlined"
                    fullWidth
                    required
                    value={rCodeNumber}
                    onChange={(e) => onRCodeHandle(e)}
                    inputProps={{ maxLength: 13, inputMode: 'numeric', pattern: '[0-9]*' }}
                />
                <Typography sx={{ fontSize: "10px", color: "red", marginBottom: 2 }}>{errorRCodeMesg}</Typography>
                <Button variant="contained" color="primary" fullWidth onClick={() => onCheckClick()}>
                    Get Data
                </Button>
                <SimpleBackdrop open={screenLoading} />
            </Box>

            {statusAvailable ?
                <Typography sx={{ color: "red", fontWeight: "bold" }}>No document found with this Request-ID.</Typography>
                :
                <Box sx={{
                    display: "flex", flexDirection: "column", alignItems: "center",
                    gap: "1rem",
                    width: "80%",
                    // background: "green"
                }}>
                    {
                        dataAvailable.reqId &&
                        <Box>
                            <Box sx={{
                                backgroundImage: `url(${gioIcon})`,
                                backgroundRepeat: "no-repeat",
                                backgroundPosition: "center",
                                backgroundSize: "contain",
                            }}>
                                <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", gap: "1rem", flexWrap: "wrap" }}>
                                    <Typography sx={{ p: 2, boxShadow: "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px", display: "flex", alignItems: "center", justifyContent: "space-between", gap: ".5rem" }}><span>Request ID: </span> <span style={{ fontWeight: "bold" }}>{dataAvailable.createdAt}</span></Typography>
                                    <Typography sx={{ p: 2, boxShadow: "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px", display: "flex", alignItems: "center", justifyContent: "space-between", gap: ".5rem" }}><span>Requested On: </span> <span style={{ fontWeight: "bold" }}>{formattedDate}</span></Typography>
                                </Box>

                                <Box p={1} />
                                <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", gap: "1rem", flexWrap: "wrap" }}>
                                    <Typography sx={{ p: 2, boxShadow: "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px", display: "flex", alignItems: "center", justifyContent: "space-between", gap: ".5rem" }}><span>isVerified: </span><span style={{ fontWeight: "bold", color: dataAvailable.isVerified === "Verified" ? "green" : "red" }}>{dataAvailable.isVerified}</span></Typography>
                                    <Typography sx={{ p: 2, boxShadow: "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px", display: "flex", alignItems: "center", justifyContent: "space-between", gap: ".5rem" }}><span>Full Name: </span> <span style={{ fontWeight: "bold" }}>{dataAvailable.fullName}</span></Typography>
                                </Box>

                                <Box p={1} />
                                <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", gap: "1rem", flexWrap: "wrap" }}>
                                    <Typography sx={{ p: 2, boxShadow: "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px", display: "flex", alignItems: "center", justifyContent: "space-between", gap: ".5rem" }}><span>Phone Number: </span> <span style={{ fontWeight: "bold" }}>{dataAvailable.phoneNumber}</span></Typography>
                                    <Typography sx={{ p: 2, boxShadow: "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px", display: "flex", alignItems: "center", justifyContent: "space-between", gap: ".5rem" }}><span>Survey Number: </span> <span style={{ fontWeight: "bold" }}>{dataAvailable.surveyNumber}</span></Typography>
                                </Box>
                            </Box>

                            <Box p={1} />
                            <Box sx={{ p: 2, boxShadow: "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px", display: "flex", flexDirection: "column", }}>
                                <Typography sx={{ fontWeight: "bold" }}>Address:</Typography>
                                <Box p={1} />
                                <Typography sx={{ p: 2, boxShadow: "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px", display: "flex", alignItems: "center", gap: ".5rem" }}><span>Address line 1: </span> <span style={{ fontWeight: "bold" }}>{dataAvailable.buildingNo}</span></Typography>
                                <Box p={1} />
                                <Typography sx={{ p: 2, boxShadow: "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px", display: "flex", alignItems: "center", gap: ".5rem" }}><span>Address line 2: </span> <span style={{ fontWeight: "bold" }}>{dataAvailable.landMark}</span></Typography>

                                <Box p={1} />
                                <Box sx={{ display: "flex", flexWrap: "wrap", width: "100%", justifyContent: "space-between", alignItems: "center" }}>
                                    <Typography sx={{ p: 2, boxShadow: "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px", display: "flex", alignItems: "center", justifyContent: "space-between", width: "20rem" }}><span>City: </span> <span style={{ fontWeight: "bold" }}>{dataAvailable.city}</span></Typography>
                                    <Typography sx={{ p: 2, boxShadow: "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px", display: "flex", alignItems: "center", justifyContent: "space-between", width: "20rem" }}><span>State: </span> <span style={{ fontWeight: "bold" }}>{dataAvailable.state}</span></Typography>
                                    <Typography sx={{ p: 2, boxShadow: "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px", display: "flex", alignItems: "center", justifyContent: "space-between", gap: ".5rem" }}><span>Pin Code: </span> <span style={{ fontWeight: "bold" }}>{dataAvailable.pinCode}</span></Typography>
                                </Box>
                            </Box>

                            <Box p={1} />
                            <Box sx={{ display: "flex", alignItems: "center", gap: 2, p: 2, boxShadow: "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px", }}>
                                <Typography> Customer Attachment:</Typography>
                                {dataAvailable.fileURL ? (
                                    <Button
                                        variant="outlined"
                                        onClick={() => window.open(dataAvailable.fileURL, '_blank')}
                                    >
                                        Download PDF
                                    </Button>
                                ) : (
                                    <Typography sx={{ color: 'gray' }}>
                                        No attachment available
                                    </Typography>
                                )}
                            </Box>

                            <Box p={1} />
                            <Box sx={{ display: "flex", alignItems: "center", gap: 2, p: 2, boxShadow: "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px", }}>
                                <Typography> Survey Map Attachment:</Typography>
                                {dataAvailable.surveyMapURL ? (
                                    <Button
                                        variant="outlined"
                                        onClick={() => window.open(dataAvailable.surveyMapURL, '_blank')}
                                    >
                                        Download PDF
                                    </Button>
                                ) : (
                                    <Typography sx={{ color: 'gray' }}>
                                        No attachment available
                                    </Typography>
                                )}
                            </Box>

                            <Box p={1} />
                            <Box sx={{ display: "flex", alignItems: "center", gap: 2, p: 2, boxShadow: "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px", }}>
                                <Typography> Invoice Attachment:</Typography>
                                {dataAvailable.invoiceURL ? (
                                    <Button
                                        variant="outlined"
                                        onClick={() => window.open(dataAvailable.invoiceURL, '_blank')}
                                    >
                                        Download PDF
                                    </Button>
                                ) : (
                                    <Typography sx={{ color: 'gray' }}>
                                        No attachment available
                                    </Typography>
                                )}
                            </Box>

                            <Box p={1} />
                            <Box sx={{ display: "flex", flexWrap: 'wrap', alignItems: "center", justifyContent: "space-between", p: 2, boxShadow: "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px", }}>
                                <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                                    {/* Upload survey map */}
                                    <Typography >Upload Survey Map PDF:</Typography>
                                    <input type="file" onChange={(e) => handleFileChange(e, setSurveyMap)} />
                                </Box>

                                <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                                    {/* Upload invoice */}
                                    <Typography >Upload Invoice PDF:</Typography>
                                    <input type="file" onChange={(e) => handleFileChange(e, setInvoice)} />
                                </Box>
                            </Box>

                            <Box p={1} />
                            <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                                {/* Select Verified Status */}
                                <Typography >Select Verification Status:</Typography>
                                <Select
                                    value={isVerified}
                                    onChange={(e) => setIsVerified(e.target.value)}
                                    sx={{ width: "180px" }}
                                >
                                    <MenuItem value="Not Verified">Not Verified</MenuItem>
                                    <MenuItem value="Verified">Verified</MenuItem>
                                    <MenuItem value="Rejected">Rejected</MenuItem>
                                </Select>
                            </Box>

                            <Box p={2} />
                            <Box
                                sx={{ display: "flex", justifyContent: "center" }}
                            >
                                {/* Submit button */}
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={onSubmit}
                                >
                                    Submit
                                </Button>
                            </Box>
                            <Box p={2} />
                        </Box>
                    }
                </Box>
            }
        </Box >
    );
};

export default FindStatus;