import { Routes, Route, Navigate } from "react-router-dom";
import HomeLayout from "./Layout/HomeLayout";
import FillApplication from "./Pages/FillApplication";
import StatusRequest from "./Pages/StatusRequest";
import AdminLoginPage from "./Pages/AdminLoginPage";
import ProtectedRoute from "./components/ProtectedRoute";
import AdminDashboard from "./Layout/AdminDashboard";
// import GenerateInvoice from "./Pages/GenerateInvoice";

const Router = () => {
    return (
        <Routes>
            {/* <Route path="/" element={<Navigate to="/home" replace />} /> */}

            <Route path="/" element={<HomeLayout />} />
            <Route path="/request_survey" element={<FillApplication />} />
            <Route path="/my_status" element={<StatusRequest />} />
            <Route path="/geocon_admin" element={<AdminLoginPage />} />

            <Route element={<ProtectedRoute />}>
                <Route path="/dashboard" element={<AdminDashboard />} />
            </Route>
            {/* <Route path="/genrateinvoice" element={<GenerateInvoice />} /> */}

        </Routes>
    );
};

export default Router;
